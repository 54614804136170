.carousel-indicator {
  display: inline-block;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  background-color: #B3B3B3;
  padding: 3px;
  border: none;
}

.indicator-active {
  background-color: #060606;
}

.carousel_arrow {
  width: 18px !important;
  max-width: 18px !important;
}

.custom-prev-icon {
  height: 35px;
  width: 18px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* background-color: #6C6C6C; */
  background-color: #c4bfbf;
  border-color: #c4bfbf;
  background-image: none;
}

.custom-next-icon {
  height: 35px;
  width: 18px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #c4bfbf;
  border-color: #c4bfbf;
  background-image: none;
}

.custom-prev-icon:hover,
.custom-next-icon:hover {
  background-color: #5f5c5c;
  border-color: #5f5c5c;
}


.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: -10px;
}

.end-0 {
  right: -10px;
}

.image-container {
  background-color: white;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-block {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}