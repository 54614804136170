#main-form {
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
}
label,#business {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

#main{
    overflow-x: hidden;
}

#address-div{    
    width: 30%;
    justify-content: center;
    margin: auto;
}

.required:before {
    content: "* ";
    color: red;
}

.label{
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.form-control{
    background-color: #EFEFEF;
}