
h1 {
    text-align:center;
}

.mainContainer {
    padding: 50px;
    background-color: white;
    padding-top: 50px;
}


.textContainer {
    padding: 20px;
    /* padding-top: 100px; */
}


.text {
    font-size: 14px;
    color: #545454;
    line-height: 20px;
    text-align: justify;
    margin-bottom: 40px;
}

.skills {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 13px;
}

.accordion-button {
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    color: #545454;
    background-color: white;
}

/* For desktop: */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}
