.rte-wrapper-class {
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 10px;
	background-color: #fff;
}

.rte-editor-class {
	background-color: #fff;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 10px;
}

.rte-toolbar-class {
	border: 1px solid #ccc;
	border-radius: 10px;
}

/*  */
.mail-templates-view {
	padding: 1rem;
}