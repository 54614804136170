.NYCE-modal>div>div {
    /* using !important to override default react-modal styles */
    border-radius: 7px !important;
    inset: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    transform: translate(-50%, -50%);
    min-width: 500px;
    max-width: 90vw;
    max-height: 90vh;
    overflow-y: scroll !important;
    box-shadow: 5px 5px 5px 5px rgba(0,0,0,0.05);

}

.NYCE-modal .NYCE-modal-header {
    color: #0079B2;
    font-weight: 600;
    font-size: 32px;
}

.NYCE-modal .NYCE-modal-close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
}

.NYCE-modal .errorMsg {
    color: #d83951;
}

.NYCE-modal .NYCE-modal-btn {
    color: #ffffff;
    background-color: #0079B2;
    border-color: #0079B2;
}