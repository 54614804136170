.card-header:first-child{
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #EFEFEF;
}


.card:hover{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
}

/* .card-image-container:hover, */
.card-header:hover{
  cursor: pointer;
}

.card-content:hover{
  cursor: pointer;
}

/* .carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: red;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: red;
} */
 
