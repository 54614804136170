.menuDiv {
    /* flex: 1; */
    /* background-color: red; */
    color: rgba(255, 255, 255, 0.65);
    background: #001529;
}
.ant-btn-Primary{
    color: rgba(255, 255, 255, 0.65);
    background-color: #1677ff;
}

.contentDiv {
    width: -webkit-fill-available;
    /* display: block; */
    flex: 1 1 100px;
/* background-color: blue; */
}
  