/* For desktop: */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}

.selectedFilterContainer {
  height: 20px;
  /* width: 100px; */
}

.crossButton {
}

.btn-default {
  /* font-family: Raleway-SemiBold; */
  /* font-size: 13px; */
  /* color: rgba(108, 88, 179, 0.75); */
  /* letter-spacing: 1px; */
  /* line-height: 15px; */
  /* border: 2px solid rgba(108, 89, 179, 0.75); */
  border: 1px solid grey;

  border-radius: 40px;
  background: transparent;
  /* transition: all 0.3s ease 0s; */
}

.categoryName {
  /* text-decoration: underline; */
  font-weight: 700;
  color: rgb(204, 48, 178);
}