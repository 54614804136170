.mainContainer {
    /* height: '100%';
    width: '100%'; */
    padding-top: 0px;
}

.viewContainer {
    padding: 50px;
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
}

.table td {
    padding: 1px;
    font-size: 0.875rem;
}
/* 
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
} */

.card:hover{
    box-shadow: none !important;
}

.col-auto{
    width: auto !important;
}

.col-6{
    width: 50% !important;
}
.col-4{
    width: 33.3% !important;
}
.col-8{
    width: 66.6% !important;
}

.fa-1_5x{
  font-size: 1.5em;
}




.priceText {
    color: #50a162;
}

.contentContainer {
    /* border: 0.5px solid grey;
    border-radius: 4px; */
    /* width: 550px; */
}

.detailsContainer {
    /* padding: 50px; */
}

.descriptionContainer {
    text-align: left;
}

.descText {
    font-weight: 300;
    color: gray;
}

.keyTextContainer {
    text-align: left;
    /* padding: 8px;
    padding-left: 10px; */
}

.valueTextContainer {
    text-align: left;
    /* padding: 8px;
    padding-left: 0px; */

}

.keyText {
    font-weight: 600;
}

.valueText {
    font-weight: 300;
}


.clock {
    overflow: hidden;
}

.top-section {
    margin-bottom: 0px;
    border-radius: 5px 5px 0px 0px;
    background-color: gray;
    border: 0px;
}

.bottom-section {
    margin-top: 0px;
    border-radius: 0px 0px 5px 5px;
    background-color: lightgrey;
    border: 0px;
}

.timerText {
    font-size: 30px;
    font-weight: 600;
    color: red;
}


.timerContainer {
    padding-top: 50px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
}

.bidValueContainer {
    padding-left: 30px;
    padding-right: 35px;
}

.buyOutButton {
    color: crimson;
}

.fa-heart:before {
    content: "\f004";
    color: red;
}

.fa-heart-o:before {
    content: "\f004";
    color: red;
}

.btn-default {
    /* border: 1px solid grey;
    border-radius: 40px;
    background: transparent; */
    /* font-family: Raleway-SemiBold; */
    /* font-size: 13px; */
    color: #513a91;
    letter-spacing: 1px;
    line-height: 19px;
    border: 2px solid rgba(108, 89, 179, 0.75);
    /* border: 1px solid grey; */

    border-radius: 40px;
    background: transparent;
    transition: all 0.3s ease 0s;
    display: inline;
    
}

.hot-list-icon, .hot-list-outline-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 4px -12px;
}
.hot-list-icon {
    background: url("../../assets/images/flame-icon.svg") no-repeat center center;
    background-size: 1.5rem 1.5rem;
}
.hot-list-outline-icon {
    background: url("../../assets/images/flame-outline-icon.svg") no-repeat center center;
    background-size: 1.5rem 1.5rem;
}

@keyframes flash {
    0% { background-color: transparent; }
    50% { background-color: var(--flash-color); }
    100% { background-color: transparent; }
  }
  
  
  .flash-animation {
    animation: flash 1s linear;
  }
  
