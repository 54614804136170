.mainContainer {
    /* align-content: center;
    justify-content: center;
    text-align: center; */
}

.centreText {
    text-align: "center";
}

.listingContainer {
    padding: 20px;
}

.productName {
    font-size: 14px;
    text-align: center;
    padding-top: 8px;
}

.bidImage {
    padding-left: 50px;
    /* padding-top: 10px; */
}

.aboutDescription {
    padding: 30px;
    padding-bottom: 20px;
}

.auctionsContainer {
    background-color: #e8e9eb;
}

.auctionsContainer {
    background-color: #e8e9eb;
    /* justify-content: center; */
}

.getStartedLink {
    text-decoration: none;
    color: black;
}

.mainContainer {
    overflow-x: hidden;
}

.favoritesContainer {
    background-color: #e6e5e3;
}

/* .carouselContainer {
    padding-left: 35px;
    padding-right: 35px;
}

.listItem {
    padding-left: 60px;
    padding-right: 30px;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
}

.carousel .slide {
    position: relative;
    margin: 0;
    text-align: center;
    padding-left: 10px;
    padding-bottom: 10px;
} */

/* @media (min-width: 768px) {
    
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(25%);
    }
    
    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
} */

/* For desktop: */
.col-1 {
    width: 8.33%;
}
.col-2 {
    width: 16.66%;
}
.col-3 {
    width: 25%;
}
.col-4 {
    width: 33.33%;
}
.col-5 {
    width: 41.66%;
}
.col-6 {
    width: 50%;
}
.col-7 {
    width: 58.33%;
}
.col-8 {
    width: 66.66%;
}
.col-9 {
    width: 75%;
}
.col-10 {
    width: 83.33%;
}
.col-11 {
    width: 91.66%;
}
.col-12 {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    [class*="col-"] {
        width: 100%;
    }
}
